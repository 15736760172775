<template>
  <div class="profile" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <heade></heade>
    <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />
    <!-- <div class="head_tt">
			<img class="logo img-responsive" src="../assets/logo3.png">
		</div> -->
    <div class="name">
      <img class="name_img d-none d-md-block" src="../assets/bk1.jpg" />
      <img class="name_img d-block d-md-none" src="../assets/bk2.jpg" />
      <div class="name_text d-none d-md-block">解决方案</div>
      <div class="name_text2 d-block d-md-none">解决方案</div>
    </div>
    <div class="text_box">
      <navw :arr="navlist"></navw>
      <div class="news_tt">
        {{ list0[this.$route.params.id] }}
      </div>
      <div v-if="this.now == 0">
        <img
          class="right_img col-12 col-sm-10 col-md-10 col-lg-8 col-xl-8"
          :class="{ active: item.active }"
          :src="item.src"
          v-for="(item, index) in list1"
          :key="index"
        />
      </div>
      <div v-if="this.now == 1">
        <img
          class="right_img col-12 col-sm-10 col-md-10 col-lg-8 col-xl-8"
          :class="{ active: item.active }"
          :src="item.src"
          v-for="(item, index) in list2"
          :key="index"
        />
      </div>
    </div>
    <!-- <div class="bow">
			<div class="icp" >
			珠海一粟科技有限公司版权所有<i class="ig"></i>
			<a class="icp_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020094226号</a>
			</div>
		</div> -->
    <bow></bow>
  </div>
</template>

<script>
import heade from "../components/head.vue";
import bow from "../components/bow.vue";
import navw from "../components/navigation.vue";
export default {
  components: {
    heade,
    bow,
    navw,
  },
  data() {
    return {
      navlist: [
        { name: "首页", link: "/" },

        { name: "解决方案", link: "/planhome" },
        { name: "详情", link: "" },
      ],
      list0: ["宽带融合通信系统", "工单管理系统"],

      list1: [
        {
          name: "宽带融合通信系统",
          src: require("../assets/no.png"),
          active: false,
        },
      ],
      list2: [
        {
          name: "宽带融合通信系统",
          src: require("../assets/no.png"),
          active: false,
        },
      ],
      now: 0,
    };
  },
  created() {
    this.now = this.$route.params.id;
  },
  methods:{
  	  mouseMove(e) {
  		 
  	     let x = e.offsetX; //相对元素的X偏移量
  	     //let y = e.offsetY; //相对元素的Y偏移量
  	     let img = document.querySelector(".name_img");
  	       let x1 = img.offsetWidth;//元素的宽度
  	      //let y1 = img.offsetHeight;//元素的高度
  	   	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
  	     document.querySelector(".name_img").style.cssText += "will-change: transform";
  	       document.querySelector(".name_img").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
  	   document.querySelector(".name_img").style.cssText += "transition: .2s ";
  	     },
  	  /*   mouseMove: function (e) {
  	       let x = e.offsetX; //相对元素的X偏移量
  	       let y = e.offsetY; //相对元素的Y偏移量
  	       let img = document.querySelector(".login100-pic");
  	       let x1 = img.offsetWidth;//元素的宽度
  	       let y1 = img.offsetHeight;//元素的高度
  	       document.querySelector(".login100-pic").style.cssText += "will-change: transform";
  	       document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
  	     } */
  	     mouseLeave: function () {
  	       document.querySelector(
  	         ".name_img"
  	       ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
  	     }
  	    
  }
};
</script>

<style scoped="scoped">
.profile {
  width: 100%;
  overflow: hidden;
}

.profile_bg {
  position: fixed;
  z-index: -1;
  width: 100%;
  min-height: 100vh;
}

.head_tt {
  width: 100%;
  height: 100px;

  background-color: #4b505e;
  /* position: fixed;
    top: 0;
    left: 0; */
}

.logo {
  height: 90px;
  display: block;
  padding: 5px;
}

.text_box {
  min-height: 1200px;
  background-color: #f0f8ff;
  position: relative;
}

.name {
  width: 100%;
  position: relative;

  color: #f0f8ff;
  text-align: center;

  background: url(../assets/yisu.png) no-repeat;
  background-size: 100%;
}

.name_img {
  width: 100%;
  margin-top: 60px;
}

.name_text {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 60px;
}

.name_text2 {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 30px;
}

.right_img {
  margin: 0px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}

.bow {
  height: 200px;
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}

.bow_home {
  color: #f0f8ff;
}

.news_tt {
  max-width: 40%;
  text-align: center;
  margin: 0 auto;
  font-size: 4vmin;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}
.active {
  width: 100vw;
}
.icp {
  height: 200px;

  color: #c6c6c6;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  /* background-color: rgba(22, 48, 126, 0.5); */
  text-align: center;

  line-height: 80px;
}
.icp_text {
  color: #c6c6c6;
  text-decoration: none;
  cursor: pointer;
}
.icp_text:hover {
  color: #fff0ff;
}
.ig {
  margin: 0 10px;
  height: 10px;
  width: 2px;
  display: inline-block;
  background-color: #c6c6c6;
}
</style>
