<template >
  <div class="home" >
    <img class="home_bg d-block d-md-none" src="../assets/home4.jpg"  />
    <img class="home_bg2 d-none d-md-block" src="../assets/home3.jpg" />
 <!--   <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />-->
    <img
      class="home_tt col-12 col-sm-8 col-md-4 col-lg-4 col-xl-4"
      src="../assets/logo3.png" 
    />
    <div class="home_box d-none d-md-block">
      <div class="home_touch">
        <div class="home_btne" v-for="(item, index) in list" :key="index">
          <router-link :to="item.link" class="btne" >
            <div class="b_txt">{{ item.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="btn_bo2 d-block d-md-none">
      <div
        class="btn_btne2 col-sm-12 col-lg-6"
        v-for="(item, index) in list"
        :key="index"
      >
        <router-link :to="item.link" class="btne2">
          <div class="btn_txt2">{{ item.name }}</div>
        </router-link>
      </div>
    </div>
    <div class="bn_text col-12">
      <div class="hb_text d-none d-lg-block">
        <img class="bn_img" src="../assets/logo2.png" @click="gogd"/>
        <p class="">珠海市高新区湾创路88号港湾 1 号·创意云端1栋4层403室</p>
      </div>
      <div class="hb_text2 d-block d-lg-none">
        <img class="bn_img2" src="../assets/logo2.png" @click="gogd"/>
        <p class="">珠海市高新区湾创路88号港湾 1 号·创意云端1栋4层403室</p>
      </div>
      <router-link
        class="our col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        to="./our"
      >
         📠️&nbsp;联系我们
      </router-link>

      <!-- <div class="our2 visible-xs">
        ☎️&nbsp;联系我们
      </div> -->
    </div>
    <div class="icp">
      <a
        class="icp_text"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >粤ICP备2020094226号</a
      >
	  <div class="no_show">珠海一粟科技有限公司成立于2020年01月14日，公司管理团队由国内著名的电子信息工程专业高级工程师、管理经验丰富的行业精英、具备多年创业经验的业内翘楚等成员组成，致力于智慧城市建设、物联网产品研发、销售及信息系统集成于一体的高新技术企业。
	  1、公司申请了17项知识产权，包含有：Ⅰ类知识产权2件，Ⅱ类知 识产权15件；分别：2项发明、8项实用新型专利、7项软件著作权。 
	  2、“代维工单管理平台产品R1.0”与华为技术有限公司合作，为华为产品“华为云鲲鹏云服务”提供相关技术服务，已完成及通过相互兼容性测试认证，并获得鲲鹏技术认证书。 
	  3、作为“数字化解决方案领导者”新华三集团的认证代理商。公司秉持“诚信 专业 优质 高效”的理念，依托移动宽带融合通信新技术，致力打造5G+政务服务新模式。
	  针对现有专网集群系统的不足，根据实际部署情况，公司研发团队重新构建了一套专网与宽带系统的语音通信方案，并获取了专利，该方案解决了专网集群对讲融入公网通信系统的最后一道屏障，深化及加强了融合通信系统的功能，提供跨网络、全覆盖的服务！
	  公司研发团队结合5G等新型互联网技术的应用开发，全面提升应急指挥调度系统的覆盖范围，通信质量，定位调度等功能，如专网语音集群对讲、移动音视频电话会议，实时移动音视频传送等，均已达到业内领先水平！
	  利用移动互联网的特性，对新型网关的进行改造， 使网关能通过移动物联网与服务器进行无线连接，能根据当前网络所在情况，自主选择2/3/4G网络进行连接，达到高效，实时，低成本运作，经过改造后的网关与车台，不需要申请专线即可实现互联，使部署更加便捷高效。
	  公网对讲业务的用户体验取决于业务平台及对讲台设备，针对手台设备的通用性、便捷性、舒适性等方面，进行大量的研究，得出多个创新突破。
	  该系统和软件，可直接适配5G等最新的移动网络技术，应用在新一代的对讲终端上（手台/警务通），使之更加适配各种应用场景，补充TETRA系统存在的短板，在视频会议、视频电话中实现实现互连互通。
	  研发团队基于现有宽带无线网络（2/3/4/5G），开发出一套的APP应用软件，可提供包括语音、数据、视频在内的所有大数据、大流量的移动互联服务；
	  工单管理系统运营商通信服务类项目公安智慧城市类项目5G/物联网相关产品研发宽带融合通信系统项目立足互联网运营，服务于营销，提供快速迭代的互联网平台开发和运营解决方案，围绕着用户经营打造基于互联网，大数据的智联营销平台，让用户和实体渠道方面快捷的随时随地，拿出手机就能办业务。
	  能为运营商提供全面通信工程服务，主要业务有：骨干、汇聚、接入层传输光缆线路、管道、传输设备、4G/5G无线设备、室分系统天馈系统及电源配套、集客、家客工程、通信机房土建等一体化施工服务；
	  维护业务范围广泛，提供各类品牌移动通信设备及4G/5G网络的维护，主要业务有：运营商通信网络综合代维（含基站及配套、传输线路、集家客、室分及WLAN等一体化维护）
	  目前通信事业部已在广东省内的广州、佛山、珠海、中山、江门、阳江、茂名、湛江开展业务，2021全年完成合同金额达9000万。公专融和对讲，融合通信，摩托罗拉对讲机。
	  紧随5G通信技术步伐，以互联网手段在赋能各实体产业实现产业互联，一粟科技以5G通信技术的大宽带、广连接和低延时的特点提出智能制造理念，为各大实体企业提供一站式解决方案。
	  智慧城市的建设要以大数据及其相关技术的发展为依托，二者密切相关。而大数据在交通管理，案件侦破，治安局势掌控，犯罪趋势预测等公安领域中的应用是智慧城市建设过程中不可或缺的部分，也需要公安机关在观念转变，组织和管理模式的革新及对外部环境变化的感知与信息交流等方面做出系统地思考和适当调整，增强公安机关对大数据及其相关技术的应用能力，改进社会治理方式，充分发挥其在建设智慧城市作用。
	  宽带融合通信业务是指基于能够提供包括语音、数据、视频和多媒体业务信息通信技术开展的全业务服务，目前全球主流电信运营商都纷纷地将融合通信业务定位为业务和技术发展的核心方向。随着IP技术的迅猛发展，日益开放的电信业务市场不断涌入新的竞争者，传统的语音业务特别是固网语音收入增幅减少甚至下滑，这迫使运营商特别是固网运营商不得不通过融合通信寻找新的业务发展空间。
	  2020年,我司联合珠海市香洲公安分局、前山街道办对辖区内治安状况特别的复杂的几个社区进行了现场勘查。如莲塘社区此前二手电动车偷盗及销赃问题比较突出,公安部门展开大清查行动,并提出了建设智感安防区的建议。在前山街道办和香洲公安分局推动下,对区内的视频监控系统进行了全面升级改造。治安管理体系改善后,该社区发案率较比上一年同期下降40.62%。
	  在各方支持和配合下,香洲区大部分的智感安防区建设项目得以大力推进,香洲区各街道办和社区紧密配合,将继续以”急民所急,想民所想”为指引,推动更多智感安防项目投入使用,进一步提升辖区群众的安全感和满意度”,努力筑牢平安建设前沿阵地,深化构建共建共治共享格局。
	  </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "公司简介",
          link: "/profile",
        },
        {
          name: "新闻资讯",
          link: "/news",
        },
        {
          name: "解决方案",
          link: "/planhome",
        },
        {
          name: "服务与支持",
          link: "/service",
        },
      ],
    };
  },
 
  methods: {
	  gogd(){
		  this.$router.push({
		    path: `/dy`,
		  });
	  },
	 mouseMove(e) {
	   let x = e.offsetX; //相对元素的X偏移量
	   //let y = e.offsetY; //相对元素的Y偏移量
	   let img = document.querySelector(".home_bg");
	     let x1 = img.offsetWidth;//元素的宽度
	    //let y1 = img.offsetHeight;//元素的高度
	 	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
	   document.querySelector(".home_bg2").style.cssText += "will-change: transform";
	     document.querySelector(".home_bg2").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
	 document.querySelector(".home_bg2").style.cssText += "transition: .2s ";
	   },
	/*   mouseMove: function (e) {
	     let x = e.offsetX; //相对元素的X偏移量
	     let y = e.offsetY; //相对元素的Y偏移量
	     let img = document.querySelector(".login100-pic");
	     let x1 = img.offsetWidth;//元素的宽度
	     let y1 = img.offsetHeight;//元素的高度
	     document.querySelector(".login100-pic").style.cssText += "will-change: transform";
	     document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
	   } */
	   mouseLeave: function () {
	     document.querySelector(
	       ".home_bg"
	     ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
	   }
	  
	 
  },
};
</script>

<style scoped="scoped">
.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  
 
}

.home_bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 50%;
  margin-left: -50vw;
  top: 0;
  z-index: 1;
  
}
.home_bg2 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 50%;
  margin-left: -50vw;
  top: 0;
  z-index: 1;
 
  
}

.home_tt {
  position: absolute;
  z-index: 5;
  left: 1.5vw;
  top: 20px;
}

.home_touch {
  width: 90%;
  margin: 0 5vw;
  height: 10vh;
  background-color: rgba(93, 112, 179, 0.6);
  clear: both;
border-radius: 20px;
  transform: skewX(-15deg);
}

.home_box {
  width: 100vw;
  height: 10vh;
  position: absolute;
  left: 0;
  z-index: 5;
  top: 25%;
  padding: 10vw 0;
  overflow:hidden;
}
.btn_bo2 {
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: 5;
  top: 25%;
}
.btn_btne2 {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 6vmin;
  margin: 40px 0;
  
}
.btne2 {
  width: 60%;
  height: 100%;
  display: block;
  color: #eeeeee;
  font-weight: 800;
  margin: 0 auto;
  text-decoration: none;
  transform: scale(1.2) skewX(-5deg);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}
.btn_btne2:hover {
  transform: scale(1.1);
  color: #ffffff;
}
.btn_btne2:hover .btn_txt2 {
  transform: scale(1.2);
}
.home_btne {
  width: 21%;
  margin: 0 2%;
  height: 10vh;
  line-height: 10vh;
  float: left;
  text-align: center;
  color: #f0f8ff;
  font-weight: 800;
  font-size: 50px;
  font-size: 3.5vmin;
  border-radius:5px;
  cursor: pointer;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transform: scale(1.2);
}

.home_btne:hover {
  background-color: rgba(255, 255, 255, 0.1);
  background: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0.1),rgba(65, 179, 255, 0.4) ,rgba(255, 255, 255, 0.1));
  
  transform: scale(1.3) rotate(-10deg);
}

.home_btne:hover .btne {
  transform: scale(1.5) rotate(10deg);
  background-position: 233px 0;
  transition: 0.2s;
  
}

.btne {
  width: 100%;
  height: 100%;
  color: white;
  display: block;
  text-decoration: none;
  transform: scale(1.2) skewX(15deg);
  text-shadow:1px 1px 1px rgba(255, 255, 255, 0.3);
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background: -webkit-linear-gradient(0deg,#a0a1a1 ,#e6e6e6,#e6e6e6,#e6e6e6, #e6e6e6,#e6e6e6, #a0a1a1 );
  font-weight: 550;
  color: transparent;
  /*设置字体颜色透明*/
  -webkit-background-clip: text;
  /*背景裁剪为文本形式*/
}

.bn_text {
  position: absolute;
  width: 100vw;

  height: 7vh;

  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(22, 48, 126, 0.5)
  );

  z-index: 5;
  color: white;
}

.bn_img {
  width: 31px;
  height: 31px;
  margin: 2px;

  top: 0;

  left: -50px;
cursor: pointer;
  position: absolute;
  z-index: -1;
}

.bn_img2 {
  width: 24px;
  height: 24px;

  top: 0px;

  left: -34px;

  position: absolute;
  z-index: -1;
}

.hb_text {
  line-height: 34px;
  width: calc(100vw - 100px);
  font-size: 18px;
  height: 30px;
  letter-spacing: 8px;
  font-weight: 100;
  display: inline-block;
  position: relative;
  margin-left: 100px;
}
.hb_text2 {
  line-height: 22px;
  width: calc(100vw -50px);
  font-size: 3vmin;

  letter-spacing: 4px;
  font-weight: 100;
  display: inline-block;
  position: relative;
  margin-left: 50px;
}

.our {
  position: absolute;
  bottom: 80px;
  right: 0px;
  font-size: 3.4vmin;
  z-index: 99;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  text-align: center;
  color: white;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  text-decoration:none
}

.our2 {
  position: absolute;
  bottom: 0px;

  font-size: 5vmin;
  z-index: 99;
  cursor: pointer;
  height: 20vh;
  width: 100%;
  text-align: center;
  line-height: 20vh;
  font-weight: 800;
}

.our:hover {
  color: #fffa67;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@keyframes ran {
  from {
    backgroud-position: 0 0;
  }

  to {
    background-position: 2000px 0;
  }
}
.icp {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  padding: 0 10px;
  color: #acacac;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  background-color: rgba(10, 23, 63, 0.7);
  text-align: center;
  overflow: hidden;
  line-height: 20px;
}
.icp_text {
  color: #e1e1e1;
  text-decoration: none;
}
.icp_text:hover {
  color: #fff0ff;
}
.profile_bg {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  max-height: 100vh;
}

</style>
