<template>
  <div class="profile" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <heade></heade>
    <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />
    <!-- <div class="head_tt">
			<img class="logo" src="../assets/logo3.png">
		</div> -->

    <div class="name">
      <img class="name_img d-none d-md-block" src="../assets/bk1.jpg" />
      <img class="name_img d-block d-md-none" src="../assets/bk2.jpg" />
      <div class="name_text d-none d-md-block">新闻资讯</div>
      <div class="name_text2 d-block d-md-none">新闻资讯</div>
    </div>

    <div class="text_box clearfix">
      <navw :arr="navlist"></navw>
      <div class="news_tt">最近消息</div>
      <div
        class="newbox col-10 col-sm-10 col-md-10 col-lg-8"
        :class="{ gg: item.h == '[新闻]' }"
        v-for="(item, index) in list"
        :key="index"
        @click="insurance(index)"
      >
        <!-- <img class="new_img" :src="item.src">
		 -->
        <div class="new_h" v-if="item.h == '[新闻]'">{{ item.h }}</div>
        <div class="new_h2" v-if="item.h == '[公告]'">{{ item.h }}</div>
        {{ item.name }}
        <div class="time">{{ item.time }}</div>
      </div>
    </div>
    <!-- <div class="bow">
			<div class="icp" >
			珠海一粟科技有限公司版权所有<i class="ig"></i>
			<a class="icp_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020094226号</a>
			</div>
		</div> -->
    <bow></bow>
  </div>
</template>

<script>
import heade from "../components/head.vue";
import bow from "../components/bow.vue";
import navw from "../components/navigation.vue";
export default {
  components: {
    heade,
    bow,
    navw,
  },
  data() {
    return {
      navlist: [
        { name: "首页", link: "/" },
        { name: "新闻资讯", link: "" },
      ],
      list: [
        {
          name: "珠海一粟科技智慧办公网络信息化综合服务项目、园区网络专项服务项目、园区监控系统服务项目单一来源成交公告",
          src: require("../assets/new2.png"),
          link: "http://www.zhsw.gov.cn/gqdt/gxq/202012/t20201203_58820688.html",
          time: "2021-9-23",
          h: "[公告]",
          txt: "pdf",
        },
        {
          name: "高新区创新创业服务中心为一粟科技提供企业服务",
          src: require("../assets/new2.png"),
          link: "http://www.zhsw.gov.cn/gqdt/gxq/202012/t20201203_58820688.html",
          time: "2020-10-30",
          h: "[新闻]",
          txt: "txt",
        },
      ],
    };
  },
  methods: {
    insurance(id) {
      //直接调用$router.push 实现携带参数的跳转
      this.$router.push({
        path: `/news/newpage/${id}`,
      });
    },
	mouseMove(e) {
			 
	   let x = e.offsetX; //相对元素的X偏移量
	   //let y = e.offsetY; //相对元素的Y偏移量
	   let img = document.querySelector(".name_img");
	     let x1 = img.offsetWidth;//元素的宽度
	    //let y1 = img.offsetHeight;//元素的高度
	 	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
	   document.querySelector(".name_img").style.cssText += "will-change: transform";
	     document.querySelector(".name_img").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
	 document.querySelector(".name_img").style.cssText += "transition: .2s ";
	   },
	/*   mouseMove: function (e) {
	     let x = e.offsetX; //相对元素的X偏移量
	     let y = e.offsetY; //相对元素的Y偏移量
	     let img = document.querySelector(".login100-pic");
	     let x1 = img.offsetWidth;//元素的宽度
	     let y1 = img.offsetHeight;//元素的高度
	     document.querySelector(".login100-pic").style.cssText += "will-change: transform";
	     document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
	   } */
	   mouseLeave: function () {
	     document.querySelector(
	       ".name_img"
	     ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
	   }
  },
};
</script>

<style scoped="scoped">
.profile {
  width: 100%;
  overflow: hidden;
}

.profile_bg {
  position: fixed;
  z-index: -1;
  width: 100%;
  min-height: 100vh;
}

.head_tt {
  width: 100%;
  height: 100px;

  background-color: #4b505e;
  /* position: fixed;
     top: 0;
     left: 0; */
}

.logo {
  height: 90px;

  padding: 5px;
}

.btn_box {
}

.text_box {
  min-height: 1000px;
  width: 100%;
  background-color: #f0f8ff;
  position: relative;
}

.name {
  width: 100%;
  position: relative;

  color: #f0f8ff;
  text-align: center;

  background: url(../assets/yisu.png) no-repeat;
  background-size: 100%;
}

.name_img {
  width: 100%;
  margin-top: 60px;
}

.name_text {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 60px;
}

.name_text2 {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 30px;
}

.time {
  width: 100%;
  text-align: right;
  font-size: 16px;
  color: #7f8487;
}

.new_h {
  display: inline-block;
  margin-right: 5px;
  color: #ee664e;
  font-weight: 800;
}

.new_h2 {
  display: inline-block;
  margin-right: 5px;
  color: #2e8295;
  font-weight: 800;
}

.bow {
  height: 200px;
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}

.newbox {
  position: relative;
  background-color: rgba(211, 211, 211, 0.5);
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border-left: 5px solid #5b73d1;
  font-size: 22px;
}
.newbox:hover {
  background-color: rgba(211, 211, 211, 0.7);
}

.gg {
  border-left: 5px solid #d19a5a;
}

.new_img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 0px;
}

.bow_home {
  color: #f0f8ff;
}

.back {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.new_name {
  position: absolute;
  left: 5%;
  bottom: 0;

  width: 90%;
  border-radius: 0;

  z-index: 5;
  font-size: 2.8vmin;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.right_name2 {
  float: left;
  font-size: 20px;
  padding-left: 320px;
  color: cadetblue;
}

.news_tt {
  max-width: 40%;
  text-align: center;
  margin: 0 auto;
  font-size: 4vmin;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  z-index: 5;
  cursor: pointer;
  //background-color: rgba(0,0,0,.5);
  border-radius: 5px;
}

.bg:hover {
  background-color: rgba(208, 208, 208, 0.5);
}
.goo {
  position: sticky;
  height: 50px;
  width: 100vw;
  background-color: #7eacb5;
  line-height: 50px;
  left: 0px;
  top: 0;
}
.icp {
  height: 200px;

  color: #c6c6c6;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  /* background-color: rgba(22, 48, 126, 0.5); */
  text-align: center;

  line-height: 80px;
}
.icp_text {
  color: #c6c6c6;
  text-decoration: none;
  cursor: pointer;
}
.icp_text:hover {
  color: #fff0ff;
}
.ig {
  margin: 0 10px;
  height: 10px;
  width: 2px;
  display: inline-block;
  background-color: #c6c6c6;
}
</style>
