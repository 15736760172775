<template>
  <div class="profile" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <heade></heade>
    <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />
    <!-- 
	  <div class="head_tt">
      <img class="logo" src="../assets/logo3.png">
    </div> -->
    <div class="name">
      <img class="name_img d-none d-md-block" src="../assets/bk1.jpg" />
      <img class="name_img d-block d-md-none" src="../assets/bk2.jpg" />
      <div class="name_text d-none d-md-block">解决方案</div>
      <div class="name_text2 d-block d-md-none">解决方案</div>
    </div>
    <div class="text_box clearfix">
      <navw :arr="navlist"></navw>
      <div class="news_tt">项目介绍</div>
      <div class="btn_box col-11 col-sm-11 col-md-10 col-lg-8">
        <div
          class="newbox col-sm-12 col-md-6 col-lg-6"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="back">
            <img class="new_img" :src="item.src" />

            <div class="new_name d-none d-md-block">{{ item.name }}</div>
            <div class="new_name2 d-block d-md-none">{{ item.name }}</div>
            <div
              class="bg col-sm-12 col-md-6 col-lg-4"
             
            ><!-- @click="insurance(index)"-->
			</div>
          </div>
        </div>
		
		
      </div>
	  <div class="news_tt">产品介绍</div>
	  <div class="btn_box col-11 col-sm-11 col-md-10 col-lg-8">
	    <div
	      class="newbox col-sm-12 col-md-6 col-lg-6"
	      v-for="(item, index) in list2"
	      :key="index"
	    >
	      <div class="back">
	        <img class="new_img" :src="item.src" />
	  	
	        <div class="new_name d-none d-md-block">{{ item.name }}</div>
	        <div class="new_name2 d-block d-md-none">{{ item.name }}</div>
	        <div
	          class="bg col-sm-12 col-md-6 col-lg-4"
	         
	        ><!-- @click="insurance(index)"-->
	  			</div>
	      </div>
	    </div>
    </div>

	 
	 
		
		
	  </div>
	  
	
    <!-- <div class="bow">
			<div class="icp" >
			珠海一粟科技有限公司版权所有<i class="ig"></i>
			<a class="icp_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020094226号</a>
			</div>
		</div> -->
    <bow></bow>
  </div>
</template>

<script>
import heade from "../components/head.vue";
import bow from "../components/bow.vue";
import navw from "../components/navigation.vue";
export default {
  components: {
    heade,
    bow,
    navw,
  },
  data() {
    return {
      navlist: [
        { name: "首页", link: "/" },

        { name: "解决方案", link: "" },
      ],
      list: [
        {
          name: "运营商通信服务类项目",
          src: require("../assets/home4.png"),
          link: "http://www.zhsw.gov.cn/gqdt/gxq/202012/t20201203_58820688.html",
        },
        {
          name: "公安智慧城市类项目",
          src: require("../assets/home4.png"),
          link: "http://www.zhuhai-hitech.gov.cn/zhgxcxcy/gkmlpt/content/2/2659/post_2659398.html#804",
        },
		{
		  name: "5G/物联网相关产品研发",
		  src: require("../assets/home4.png"),
		  link: "http://www.zhuhai-hitech.gov.cn/zhgxcxcy/gkmlpt/content/2/2659/post_2659398.html#804",
		}, {
          name: "宽带融合通信系统项目",
          src: require("../assets/home4.png"),
          link: "http://www.zhuhai-hitech.gov.cn/zhgxcxcy/gkmlpt/content/2/2659/post_2659398.html#804",
        },
      ],
	  list2: [
	    {
	      name: "宽带融合通信系统",
	      src: require("../assets/home4.png"),
	      link: "http://www.zhsw.gov.cn/gqdt/gxq/202012/t20201203_58820688.html",
	    },
	    {
	      name: "工单管理系统",
	      src: require("../assets/home4.png"),
	      link: "http://www.zhuhai-hitech.gov.cn/zhgxcxcy/gkmlpt/content/2/2659/post_2659398.html#804",
	    },
	  		
	  ],
    };
  },
  methods: {
    insurance(id) {
      //直接调用$router.push 实现携带参数的跳转
      this.$router.push({
        path: `/planhome/plan/${id}`,
      });
    },
	mouseMove(e) {
			 
	   let x = e.offsetX; //相对元素的X偏移量
	   //let y = e.offsetY; //相对元素的Y偏移量
	   let img = document.querySelector(".name_img");
	     let x1 = img.offsetWidth;//元素的宽度
	    //let y1 = img.offsetHeight;//元素的高度
	 	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
	   document.querySelector(".name_img").style.cssText += "will-change: transform";
	     document.querySelector(".name_img").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
	 document.querySelector(".name_img").style.cssText += "transition: .2s ";
	   },
	/*   mouseMove: function (e) {
	     let x = e.offsetX; //相对元素的X偏移量
	     let y = e.offsetY; //相对元素的Y偏移量
	     let img = document.querySelector(".login100-pic");
	     let x1 = img.offsetWidth;//元素的宽度
	     let y1 = img.offsetHeight;//元素的高度
	     document.querySelector(".login100-pic").style.cssText += "will-change: transform";
	     document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
	   } */
	   mouseLeave: function () {
	     document.querySelector(
	       ".name_img"
	     ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
	   }
	
  },
};
</script>

<style scoped="scoped">
.profile {
  width: 100%;
  overflow: hidden;
}

.profile_bg {
  position: fixed;
  z-index: -1;
  width: 100%;
  min-height: 100vh;
}

.head_tt {
  width: 100%;
  height: 100px;

  background-color: #4b505e;
  /* position: fixed;
    top: 0;
    left: 0; */
}

.logo {
  height: 90px;

  padding: 5px;
}

.btn_box {
  margin: 0 auto;
}

.text_box {
  min-height: 1000px;
  width: 100%;
  background-color: #f0f8ff;
  position: relative;
}

.name {
  width: 100%;
  position: relative;

  color: #f0f8ff;
  text-align: center;

  background: url(../assets/yisu.png) no-repeat;
  background-size: 100%;
}

.name_img {
  width: 100%;
  margin-top: 60px;
}

.name_text {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 60px;
}

.name_text2 {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 30px;
}

.bow {
  height: 200px;
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}

.newbox {
  position: relative;
  display: inline-block;
}

.new_img {
  width: 100%;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
}

.bow_home {
  color: #f0f8ff;
}

.back {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
}

.new_name {
  position: absolute;
  left: 10%;
  top: 30%;
  width: 80%;
  
  text-align: center;
  z-index: 5;
  height: 40px;
  line-height:40px;
  font-size: 3.4vmin;
  //font-weight: 800;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-radius: 5px ;
}

.new_name2 {
  position: absolute;
  left: 10%;
  top: 30%;
  width: 80%;
  height: 40px;
  line-height:40px;
  font-family:"宋体";
  text-align: center;
  z-index: 5;
  font-size: 22px;
    border-radius: 5px ;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.right_name2 {
  float: left;
  font-size: 20px;
  padding-left: 320px;
  color: cadetblue;
}

.news_tt {
  max-width: 40%;
  text-align: center;
  margin: 0 auto;
  font-size: 4vmin;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  z-index: 5;
  cursor: pointer;
  //background-color: rgba(0,0,0,.5);
  border-radius: 5px;

  background: linear-gradient(
    to bottom,
	  rgba(219, 225, 255, 0.5),
 
    rgba(155, 239, 255, 0)
  );
}

.back:hover .bg{
	  box-shadow: 2px 0px 30px #25e2f7 inset;
 /* background: linear-gradient(
    to bottom,
     rgba(90, 148, 255, 0.6),
    rgba(155, 239, 255, 0)
  ); */
border-radius: 5px;
}
.back:hover .new_name2{
 /* background: linear-gradient(
    to bottom,
     rgba(90, 148, 255, 0.6),
    rgba(155, 239, 255, 0)
  ); */
   font-weight: 800;
   background-color: rgba(255, 255, 0, 0.9);
    color: black;
}
.back:hover .new_name{
	  font-weight: 800;
 /* background: linear-gradient(
    to bottom,
     rgba(90, 148, 255, 0.6),
    rgba(155, 239, 255, 0)
  ); */
  background-color: rgba(255, 255, 0, 0.9);
  color: black;
}
.back:hover .new_img{
 transform: scale(1.4);
 transition: .3s;
}

.icp {
  height: 200px;
  color: #c6c6c6;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  /* background-color: rgba(22, 48, 126, 0.5); */
  text-align: center;
 line-height: 80px;
}
.icp_text {
  color: #c6c6c6;
  text-decoration: none;
  cursor: pointer;
}
.icp_text:hover {
  color: #fff0ff;
}
.ig {
  margin: 0 10px;
  height: 10px;
  width: 2px;
  display: inline-block;
  background-color: #c6c6c6;
}
</style>
