<template>
  <div class="bowe">
    <div class="icp">
      珠海一粟科技有限公司版权所有<i class="ig"></i>
      <a
        class="icp_text"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >粤ICP备2020094226号</a
      >
    </div>
    <div class="bott"></div>
  </div>
</template>

<script></script>

<style scoped="scoped">
.bowe {
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  font-size: 20px;
  position: relative;
}
.bow_home {
  color: #f0f8ff;
}
.icp {
  color: #c6c6c6;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  /* background-color: rgba(22, 48, 126, 0.5); */
  text-align: center;
  padding: 40px 0;
  line-height: 20px;
}
.icp_text {
  color: #c6c6c6;
  text-decoration: none;
  cursor: pointer;
}
.icp_text:hover {
  color: #fff0ff;
}
.ig {
  margin: 0 10px;
  height: 10px;
  width: 2px;
  display: inline-block;
  background-color: #c6c6c6;
}
.bott {
  width: 100vw;
  height: 100px;
  position: fixed;
  bottom: -50px;
  z-index: -1;
  background-color: #4b505e;
}
</style>
