<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  height: 0;
}
html {
  width: 100vw;
  overflow-x: hidden;
}
#app {
  width: 100vw;

  overflow: hidden;
  //background-color: #4871b3;
  position: relative;
}
</style>
