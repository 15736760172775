<template>
	<div>	
	<div class="d-none d-lg-block">
	
	<div class="bg0">
	<div class="tt">代维管理调度系统</div>
	<div class="bd">
		
	<div class="gdbox">
	<img class="gdimg" src="../assets/logoss.png" />
	
	<a class="list l0" @click="fun()">
			<div class="textbox">
				<div class="text0 t">
					数据存储
				</div>
			</div>
		</a>
		<a class="list l1" rel="noopener noreferrer noreferrer"  href="https://ts.zh1su.com/admin/index/login">
			<div class="textbox" >
				<div class="text0 t" >
					工单管理
				</div>
			</div>
		</a>
		<a class="list l2" @click="fun()">
		
			<div class="textbox">
				<div class="text1 t">
				综合调度
				</div>
			</div>
		</a>
		<a class="list l3"  @click="fun()">
		
			<div class="textbox">
				<div class="text2 t">
					项目协同
				</div>
			</div>
		</a>
		<a class="list l4" @click="fun()">
		
			<div class="textbox">
				<div class="text2 t">
					资产管理
				</div>
			</div>
		</a>
		<div class="slh0 sh" >……</div>
		<div class="slh1 sh">……</div>
		
		<div class="gdbox2">		
		</div>
		<div class="gdbox3">		
		</div>
		<div class="gd4">
		</div>
		</div>
		</div>
		</div>
		</div>
		
		<div  class="phbg d-block d-lg-none">
		<div class="tt2">代维管理调度系统</div>
		<div class="bb2">
			<a class="list2" @click="fun()">
				<div class="textbox2">
					<div class="text3">
					综合调度
					</div>
				</div>
			</a>
			<a class="list2"  href="https://www.zh1su.com:9000" >
				<div class="textbox2">
					<div class="text3">
						工单管理
					</div>
				</div>
			</a>
			
		    
		     	<a class="list2"  @click="fun()">
		     	
		     		<div class="textbox2">
		     			<div class="text3">
		     				项目协同
		     			</div>
		     		</div>
		     	</a>
		     	<a class="list2" @click="fun()">
		     	
		     		<div class="textbox2">
		     			<div class="text3">
		     				资产管理
		     			</div>
		     		</div>
		     	</a>
				<a class="list2" @click="fun()">
				
					
						<div class="textbox2">
							<div class="text3">
								数据存储
							</div>
						</div>
					</a>
				
				</div>
				</div>
		</div>
</template>

<script>
	
	export default {
	 
	  data() {
	    return {
	     
	      
	    };
	  },
	  methods: {
		  
	   fun(){
		   alert("网址维护中");
	   }
	  },
	};
</script>

<style scoped>
	
	
	
	.bg0{margin: 0;
	    padding: 0;
		width: 100vw;
		height: 100vh;
		background: -webkit-linear-gradient(0deg, #CBE1FF,#F3F8FF,#CBE1FF);
		
		//background:#d3d3d3;
		//background: -webkit-linear-gradient(45deg, #e1e4eb, #fffffc);
	}
	.tt{
		width: 1248px;
		font-size: 36px;
		font-weight: 800;
		position: fixed;
		top: 100px;
		left:50% ;
		margin-left: -624px;
		
		
		height: 60px;
		
		line-height: 60px;
		text-align: center;
		//background-color: #fff ;
		color: #013066;
		z-index: 999;
	}
	.bd{
		position: fixed;
		top:50%;
		width: 100%;
		left: 0;
		margin-top:-318px ;
		//bottom:0;
	}
	.gdbox{
		width: 1248px;
		height:636px;
		//background:#fff;
		margin: 0 auto;
		position: relative;
		//overflow: hidden;
	}
	.gdbox1{
		width: 100vw;
		height:100vh;
		background: -webkit-linear-gradient(0deg, #CBE1FF,#F3F8FF,#CBE1FF);
		position: absolute;
		left: 0;
		bottom: 0px;
		//border-radius: 50%;
		z-index: 2;
		transition: all .6s;
	}
	.gdbox2{
		width: 700px;
		height:700px;
		background: -webkit-linear-gradient(0deg, #97C4FF,#E7F2FE,#97C4FF);
		position: absolute;
		left:274px;
		transition: all 1s;
		bottom: -350px;
		border-radius: 50%;
		z-index: 3;
	}
	.gdbox3{
		width: 518px;
		height:518px;
		background: -webkit-linear-gradient(45deg, #0575FF,#40CFFF,#0575FF);
		position: absolute;
		left:365px;
		transition: all 1s;
		bottom: -259px;
		border-radius: 50%;
		z-index: 3;
	}
	.gd4{
	width: 1248px;
	height:1248px;
		left:50%;
		margin-left: -624px;
		
	position: absolute;
	background: -webkit-linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,1),transparent);
	
		top:50%;
		margin-top: 312px;
		//border-radius: 50%;
		z-index:7;
	}
	.gdimg{
		width: 172px;
		height: 172px;
		position: absolute;
		left:538px;
		
		bottom: 40px;
		z-index: 4;
	}
	.list{
		width: 32px;
		height: 32px;
		border: 2px solid #fff;
	    background: -webkit-linear-gradient(125deg, #0575FF,#40CFFF);
		border-radius: 50%;
		cursor: pointer;
		transition: all .1s;
		box-shadow: 4px 4px 15px #cccccc;
		
	}
	.list:hover{
	background: -webkit-linear-gradient(125deg, #1a8817,#8aff8a);
	transform: scale(1.15); 
		
	}
	.list:hover~.gdbox3{
		transform: scale(1.1);
		
		 
	}
	.list:hover~.gdbox2{
		transform: scale(.8); 
		
	}
	.list:hover~.gdbox1{
		transform: scale(2); 
		
	}
	.gdbox1:hover{
		transform: scale(2); 
		
	}
	.l0{
		position: absolute;
		left:284px;	
		top: 473px;
		z-index: 5;
	}
	.l1{
		position: absolute;
		left:400px;	
		top: 338px;
		z-index: 5;
	}
	.l2{
		position: absolute;
		left:605px;	
		top: 258px;
		z-index: 5;
	}
	.l3{
		position: absolute;
		right:400px;
		top: 338px;
		z-index: 5;
	}
	.l4{
		position: absolute;
		right:284px;	
		top: 473px;
		z-index: 5;
	}
	
	.textbox{
		position: relative;
	}
	.text0{
		position: absolute;
		right: 38px;
		top:-40px;
		font-size:30px;
		color: #006EFF;
		font-weight: 800;
		width: 260px;
		transition: all .1s;
		text-align: right;
	}
	.text1{
		position: absolute;
		right: -106px;
		top:-55px;
		font-size:30px;
		color: #006EFF;
		font-weight: 800;
		width:260px;
		transition: all .1s;
		text-align: center;
		
	}	.text2{
		position: absolute;
		left: 38px;
		top:-40px;
		font-size:30px;
		color: #006EFF;
		font-weight: 800;
		width: 260px;
		transition: all .1s;
		text-align: left;
	}
	.list:hover .t{
		//transform: scale(.9); 
		color: #1a8817;
	}
	.slh0{
		position: absolute;
		left: 130px;
		bottom: 70px;
		color: #006EFF;
		font-size: 30px;
		z-index: 6;
		cursor: pointer;
	}
	.slh1{
		position: absolute;
		right: 130px;
		bottom: 70px;
		color: #006EFF;
		font-size: 30px;
		z-index: 6;
		cursor: pointer;
	}
	.sh{
		transition: all .1s;
	}
	.sh:hover{
		color: #1a8817;
		
	}
	.sh:hover~.gdbox3{
		transform: scale(1.1); 
	}
	.sh:hover~.gdbox2{
		transform: scale(.8); 
	}
	.sh:hover~.gdbox1{
		transform: scale(1.2); 
	}
	
	
	.phbg{
		width: 100vw;
		height: 100vh;
		background: -webkit-linear-gradient(0deg, #CBE1FF,#F3F8FF,#CBE1FF);
		position: relative;
	}
	.list2{
		display: block;
		height: 40px;
		    line-height: 40px;
		    text-align: center;
		    font-size: 6vmin;
		    margin: 40px 0;
		  text-decoration:none;
		
	}
	.tt2{
		width: 1248px;
		font-size: 30px;
		font-weight: 800;
		position: fixed;
		top: 100px;
		
		left:50% ;
		margin-left: -624px;
		height: 60px;
		
		line-height: 60px;
		text-align: center;
		//background-color: #fff ;
		color: #013066;
		z-index: 999;
	}
	.textbox2{
		width: 60%;
		height: 100%;
		display: block;
		color: #0575FF;
		font-weight: 800;
		margin: 0 auto;
		text-decoration: none;
		transform: scale(1.2) skewX(-5deg);
		background-color: rgba(73, 167, 255, 0.1);
		border-radius: 12px;
		text-align: center;
	}
	.bb2{
		width: 100vw;
		    position: absolute;
		    left: 0;
		    z-index: 5;
		    top: 25%;
	}
</style>