<template>
  <div class="profile" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <heade></heade>
    <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />
    <!-- <div class="head_tt">
   <img class="logo " src="../assets/logo3.png">
    </div> -->

    <div class="name">
      <img class="name_img d-none d-md-block" src="../assets/bk1.jpg" />
      <img class="name_img d-block d-md-none" src="../assets/bk2.jpg" />
      <div class="name_text d-none d-md-block">联系我们</div>
      <div class="name_text2 d-block d-md-none">联系我们</div>
    </div>
    <div class="text_box">
      <navw :arr="navlist">
		
	  </navw>
      <div class="news_tt col-11 col-sm-11 col-md-10 col-lg-5 col-xl-5">地址电话</div>
	 
	  <div class=" r_box  col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
		    <img class="our_logo" src="../assets/logo_h2.png" alt="">
			<br/>
			<text class="our_tittle">珠海一粟科技有限公司</text>
			<br/>
			<text class="our_text">
			珠海市高新区湾创路88号港湾 1 号·创意云端1栋4层403室<br/>
			联系电话：0756-3388683<br/></text>
			
	  </div>
	  
		<div    class="right_img col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6"
       
        v-for="(item, index) in list"
        :key="index">
		<img class="ou_img" :src="item.src" />
		</div>
   
    </div>
    <!-- <div class="bow ">
       <router-link class="bow_home" to="/">
         回到首页>
       </router-link>
    </div> -->
    <bow></bow>
  </div>
</template>

<script>
import heade from "../components/head.vue";
import bow from "../components/bow.vue";
import navw from "../components/navigation.vue";
export default {
  components: {
    heade,
    bow,
    navw,
  },
  data() {
    return {
      navlist: [
        { name: "首页", link: "/" },

        { name: "关于我们", link: "" },
      ],
      list: [
      

        { name: "公司简介", src: require("../assets/dt.png") },
		
      ],
    };
  },
  methods:{
  	  mouseMove(e) {
  		 
  	     let x = e.offsetX; //相对元素的X偏移量
  	     //let y = e.offsetY; //相对元素的Y偏移量
  	     let img = document.querySelector(".name_img");
  	       let x1 = img.offsetWidth;//元素的宽度
  	      //let y1 = img.offsetHeight;//元素的高度
  	   	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
  	     document.querySelector(".name_img").style.cssText += "will-change: transform";
  	       document.querySelector(".name_img").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
  	   document.querySelector(".name_img").style.cssText += "transition: .2s ";
  	     },
  	  /*   mouseMove: function (e) {
  	       let x = e.offsetX; //相对元素的X偏移量
  	       let y = e.offsetY; //相对元素的Y偏移量
  	       let img = document.querySelector(".login100-pic");
  	       let x1 = img.offsetWidth;//元素的宽度
  	       let y1 = img.offsetHeight;//元素的高度
  	       document.querySelector(".login100-pic").style.cssText += "will-change: transform";
  	       document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
  	     } */
  	     mouseLeave: function () {
  	       document.querySelector(
  	         ".name_img"
  	       ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
  	     }
  	    
  }
};
</script>

<style scoped="scoped">
.profile {
  width: 100vw;
}
.profile_bg {
  position: fixed;
  z-index: -1;
  width: 100%;
  min-height: 100vh;
}
.head_tt {
  width: 100%;
  height: 100px;

  background-color: #4b505e;
  /* position: fixed;
    top: 0;
    left: 0; */
}
.logo {
  height: 100px;
  display: block;
  padding: 5px;
}
.text_box {
  min-height: 500px;
  background-color: #f0f8ff;
  position: relative;

  padding-bottom: 30px;
}
.name {
  width: 100%;
  position: relative;

  color: #f0f8ff;
  text-align: center;

  background: url(../assets/yisu.png) no-repeat;
  background-size: 100%;
}
.name_img {
  width: 100%;
  margin-top: 60px;
}
.name_text {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 60px;
}
.name_text2 {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 30px;
}

.right_img {
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}
.bow {
  height: 200px;
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}
.bow_home {
  color: #f0f8ff;
}
.news_tt {
 
  text-align: center;
  margin: 0 auto;
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
}
.our_logo{
	width: 200px;
	margin: 0 auto;
}
.our_tittle{
	font-size: 18px;
	font-weight: 800;
	color: blue;
	text-align: center;
}
.our_text{
	line-height: 30px;
	font-size: 14px;
	text-align: center;
}
.r_box{
	padding:20px;
	margin: 0px auto;
	margin-top: 0px;
	margin-bottom: 10px;
	display: block;
	border-radius: 10px;
}
.ou_img{
	width: 100%;
	cursor: pointer;
}
.right_img:hover .ou_img{
	transform: scale(1.6);
	transition: .5s;
}
</style>
