import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios'
import vueAxios from 'vue-axios'
const app=createApp(App);
app.use(vueAxios,axios)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store";
createApp(App).use(store).use(router).mount("#app");
