import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import profile from "../views/profile.vue";
import news from "../views/news.vue";
import plan from "../views/plan.vue";
import plan_home from "../views/planhome.vue";
import service from "../views/service.vue";
import newpage from "../views/new_page.vue";
import our from "../views/our.vue";
import registerUser from "../views/registerUser.vue";
import video from "../views/video.vue";
import dy from "../views/dy.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
	  content:{
	          keywords:'珠海一粟,一粟科技,对讲机终端,调度系统,应急通信系统,公专融和对讲,珠海',
	          description:'珠海一粟科技有限公司是专业无线通信的解决方案提供商,多年来专注于对讲机终端、调度系统、应急通信系统等专业无线通信设备的研发、销售和服务。'
	  }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: profile,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/news",
    name: "news",
    component: news,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/planhome/plan/:id",
    name: "planhome/plan",
    component: plan,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/planhome",
    name: "plan_home",
    component: plan_home,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/service",
    name: "service",
    component: service,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/news/newpage/:id",
    name: "news/newpage/:id",
    component: newpage,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/registerUser",
    name: "registerUser",
    component: registerUser,
    meta: {
      // 页面标题title
      title: "移动公专账号申请",
    },
  },
  {
    path: "/our",
    name: "our",
    component: our,
    meta: {
      // 页面标题title
      title: "珠海一粟科技有限公司",
    },
  },
  {
    path: "/dy",
    name: "dy",
    component: dy,
    meta: {
      // 页面标题title
      title: "代维管理调度系统",
    },
  },
  {
    path: "/video/:id",
    name: "video",
    component: video,
    meta: {
      // 页面标题title
      title: "共享视频",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
