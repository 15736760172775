<template>
  <div class="etext_box">
    <!-- <img class="logo_h  d-none d-md-block" src="../assets/logo_h.png">
		<img class="logo_h  d-block d-md-none" src="../assets/logo3.png"> -->
    <img class="logo_h" src="../assets/logo3s.png" />

    <div class="right_h d-none d-sm-block">
      <router-link to="/">
        <img class="rightb_h_img" src="../assets/home.png"
      /></router-link>
      <img
        v-if="
          this.$route.path != '/profile' &&
          this.$route.path != '/news' &&
          this.$route.path != '/planhome' &&
          this.$route.path != '/service' &&
          this.$route.path != '/our'
        "
        class="rightb_h_img"
        src="../assets/back.png"
        @click="$router.go(-1)"
      />
    </div>
    <div class="right_h d-block d-sm-none">
      <div
        v-if="
          this.$route.path != '/profile' &&
          this.$route.path != '/news' &&
          this.$route.path != '/planhome' &&
          this.$route.path != '/service' &&
          this.$route.path != '/our'
        "
      >
        <img
          class="rightb_h_img2"
          src="../assets/Category.png"
          @click="typee()"
        />
        <div class="btn_type" :class="{ typeg: type1 }">
          <router-link to="/" style="text-decoration: none">
            <div class="type_list">回到首页</div>
          </router-link>
          <div class="type_list" @mousedown="$router.go(-1)">返回上层</div>
        </div>
      </div>
      <router-link to="/" v-else>
        <img class="rightb_h_img" src="../assets/home.png"
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type1: false,
    };
  },
  methods: {
    typee() {
      if (this.type1 == true) {
        this.type1 = false;
      } else {
        this.type1 = true;
      }
    },
  },
};
</script>

<style scoped="scoped">
.etext_box {
  position: fixed;
  height: 60px;
  width: 100vw;

  top: 0px;
  left: 0;
  background-color: #4b505e;
  z-index: 50;
  clear: both;
}
.logo_h {
  height: 60px;
  margin-left: 5px;
  float: left;
}
.right_h {
  width: 132px;
  height: 60px;
  clear: both;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;
}
.rightb_h_img {
  float: right;
  margin: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  opacity: 0.5;
  margin-left: 7.5px;
}
.rightb_h_img2 {
  float: right;
  margin: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  opacity: 0.5;
  margin-left: 7.5px;
}
.rightb_h_img:hover {
  opacity: 1;
}
.rightb_h_img2:active {
  opacity: 1;
}
.btn_type {
  position: fixed;
  overflow: hidden;
  right: 0;
  top: 60px;
  width: 106px;
  height: 0;
  transition: 0.1s;
  background-color: #4b505e;
}
.typeg {
  height: 80px;
  border: 1px solid #797979;
  border-top: 0;
}
.type_list {
  height: 39px;
  border-top: 1px solid #797979;
  clear: both;
  color: #aaaaaa;
  line-height: 40px;
  text-align: center;
}
.type_list:hover {
  color: #e1e1e1;
  background-color: #3c404b;
}
</style>
