<template>
  <div class="profile" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <heade></heade>
    <img class="profile_bg d-none d-md-block" src="../assets/home3.jpg" />
    <img class="profile_bg d-block d-md-none" src="../assets/home4.jpg" />
    <!-- <div class="head_tt">
   <img class="logo " src="../assets/logo3.png">
    </div> -->
    <div class="name">
      <img class="name_img d-none d-md-block" src="../assets/bk1.jpg" />
      <img class="name_img d-block d-md-none" src="../assets/bk2.jpg" />
      <div class="name_text d-none d-md-block">公司简介</div>
      <div class="name_text2 d-block d-md-none">公司简介</div>
    </div>

    <div class="text_box" >
      <navw :arr="navlist"></navw>
      <!-- <div class="news_tt ">
        关于我们
      </div> 
	  v-for="(item, index) in list" :key="index"
	  <img v-if="item.type=='img'" class="right_img " :src="item.src"/>-->
	  <div   class="right_img col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8"  >
		  <div class="tittle">
		  			科技&nbsp;&nbsp;创新&nbsp;&nbsp;合作&nbsp;&nbsp;共赢
		  		</div>
		  		<div class="our_text">
		  			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>珠海一粟科技有限公司</b>成立于2020年，作为一家专精特新企
业，公司致力于智慧城市建设、物联网产品研发、信息系统集成，
秉承“诚信、专业、优质、高效”的理念，凭借丰富的行业经验
和卓越的管理团队，依托移动宽带融合通信新技术，致力打造
5G+政务服务新模式。<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司成立至今，先后获得高新技术企业、创新型中小企业、
专精特新企业（省级）称号；我司产品“代维工单管理平台产品
R1.0”已经完成及通过“华为云—鲲鹏云服务” 相互兼容性测
试认证，并获得鲲鹏技术认证证书；作为“数字化解决方案领导
者”获得多个品牌方代理认证，截至目前，获得知识产权24项。
		  
		  		</div>
		  <div class="in_box col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
			  <div class="in_imgbox">
				    <img class="in_img" src="../assets/xcl.png" alt="">
			  </div>
			
			  <div class="in_text">
				  <text>
					   珠海一粟公司宣传展示墙
				  </text>
				
			  </div>
			
		  </div>
		  <div class="in_box col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
			  <div class="in_imgbox">
			  				    <img class="in_img" src="../assets/gxq.png" alt="">
			  </div>
		  			 
					  <div class="in_text">
						  <text>
							   珠海市高新区港湾1号面貌
						  </text>
					  				 
					  </div>
		  </div>
		  <img style="width: 80%;margin-top: 50px;margin: 0 10%;"  src="../assets/4.png" alt="">
		 <div class="tittle2">
			 让/城/市/及/物/联/网/更/智/慧
		 </div>
		<div class="our_text">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司秉持“诚信 专业 优质 高效”的理念，依托移动宽带融合通信新技术，致力打造5G+政务服务新模式。针对现有专网集群系统的不足，根据实际部署情况，公司研发团队重新构建了一套专网与宽带系统的语音通信方案，并获取了专利，该方案解决了专网集群对讲融入公网通信系统的最后一道屏障，深化及加强了融合通信系统的功能，提供跨网络、全覆盖的服务！公司研发团队结合5G等新型互联网技术的应用开发，全面提升应急指挥调度系统的覆盖范围，通信质量，定位调度等功能，如专网语音集群对讲、移动音视频电话会议，实时移动音视频传送等，均已达到业内领先水平！
		
				</div>
				<div class="tittle">发展历程</div>
					
				<img style="width: 80%;margin: 0 10%;margin-bottom: 20px;" src="../assets/8.png" alt="">
							
				
				<div class="tittle">荣誉资质</div>
				<img style="width: 80%;margin: 0 10%;margin-bottom: 50px;" src="../assets/6.png" alt="">
			
		</div>
    </div>
    <!-- <div class="bow ">
     <div class="icp" >
     珠海一粟科技有限公司版权所有<i class="ig"></i>
     <a class="icp_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020094226号</a>
     </div>
    </div> -->
    <bow></bow>
  </div>
</template>

<script>
import heade from "../components/head.vue";
import bow from "../components/bow.vue";
import navw from "../components/navigation.vue";
export default {
  components: {
    heade,
    bow,
    navw,
  },
  data() {
    return {
      navlist: [
        { name: "首页", link: "/" },

        { name: "公司简介", link: "" },
      ],
	  
      list: [
        { name: "公司简介", src: require("../assets/2.png"),type:"img" },
		 { name: "公司简介",type:"tittle" ,tittle:"科技&nbsp;&nbsp;创新&nbsp;&nbsp;合作&nbsp;&nbsp;共赢"},
        { name: "公司简介", src: require("../assets/3.png"),type:"img" },
		 { name: "公司简介",type:"tittle" ,tittle:"让城市及互联网更智慧"},
		 { name: "公司简介",type:"text",text:"珠海一粟科技有限公司成立于2020年01月14日，公司管理团队由国内著名的电子信息工程专业高级工程师、管理经验丰富的行业精英、具备多年创业经验的业内翘楚等成员组成，致力于智慧城市建设、物联网产品研发、销售及信息系统集成于一体的高新技术企业。1、公司申请了17项知识产权，包含有：Ⅰ类知识产权2件，Ⅱ类知 识产权15件；分别：2项发明、8项实用新型专利、7项软件著作权。 2、“代维工单管理平台产品R1.0”与华为技术有限公司合作，为华为产品“华为云鲲鹏云服务”提供相关技术服务，已完成及通过相互兼容性测试认证，并获得鲲鹏技术认证书。 3、作为“数字化解决方案领导者”新华三集团的认证代理商。" },
        { name: "公司简介", src: require("../assets/4.png"),type:"img" },
      ],
    };
  },
  methods:{
	  mouseMove(e) {
		 
	     let x = e.offsetX; //相对元素的X偏移量
	     //let y = e.offsetY; //相对元素的Y偏移量
	     let img = document.querySelector(".name_img");
	       let x1 = img.offsetWidth;//元素的宽度
	      //let y1 = img.offsetHeight;//元素的高度
	   	 //  document.querySelector(".home_bg2").style.cssText += "will-change:background-position-x";
	     document.querySelector(".name_img").style.cssText += "will-change: transform";
	       document.querySelector(".name_img").style.cssText += `transform:perspective(0px) translateX(${(x1 / 2 - x) / 40}px) scale(1.05) `;
	   document.querySelector(".name_img").style.cssText += "transition: .2s ";
	     },
	  /*   mouseMove: function (e) {
	       let x = e.offsetX; //相对元素的X偏移量
	       let y = e.offsetY; //相对元素的Y偏移量
	       let img = document.querySelector(".login100-pic");
	       let x1 = img.offsetWidth;//元素的宽度
	       let y1 = img.offsetHeight;//元素的高度
	       document.querySelector(".login100-pic").style.cssText += "will-change: transform";
	       document.querySelector(".login100-pic").style.cssText += `transform:perspective(300px) rotateX(${(x1 / 2 - x) / 20}deg) rotateY(${(y1 / 2 - y) / 20}deg) scale3d(1.1,1.1,1.1)`;
	     } */
	     mouseLeave: function () {
	       document.querySelector(
	         ".name_img"
	       ).style.transform ="perspective(300px) rotateX(0deg) rotateY(0deg)";
	     }
	    
  }
};
</script>

<style scoped="scoped">
.profile {
  width: 100%;
  overflow: hidden;
}
.profile_bg {
  position: fixed;
  z-index: -1;
  width: 100vw;
  min-height: 100vh;
}
.head_tt {
  width: 100vw;
  height: 100px;

  background-color: #4b505e;
  /* position: fixed;
    top: 0;
    left: 0; */
}
.logo {
  height: 100px;
  display: block;
  padding: 5px;
}
.text_box {
  min-height: 800px;
  background-color: #f0f8ff;
  position: relative;
}
.name {
  width: 100%;
  position: relative;

  color: #f0f8ff;
  text-align: center;
  background: url(../assets/yisu.png) no-repeat;
  background-size: 100%;
}
.name_img {
  width: 100%;
  margin-top: 60px;
  position: relative;
}
.name_img:before{
	content: ' After pseudo-element';
	width: 100vw;
	height: 200px;
	position: absolute;
	display: inline-block;
	left: 0;
	top: 0;
	background-color: #c6c6c6;
	z-index: 999;
}

.name_text {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 60px;
}
.name_text2 {
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  width: 100vw;
  line-height: 100px;
  font-size: 30px;
}

.right_img {
  margin: 0px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}
.bow {
  height: 200px;
  background-color: #4b505e;
  width: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}
.bow_home {
  color: #f0f8ff;
}
.news_tt {
  max-width: 40%;
  text-align: center;
  margin: 0 auto;
  font-size: 4vmin;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
}
.icp {
  height: 200px;

  color: #c6c6c6;
  z-index: 100;
  font-size: 12px;
  width: 100vw;
  /* background-color: rgba(22, 48, 126, 0.5); */
  text-align: center;

  line-height: 80px;
}
.icp_text {
  color: #c6c6c6;
  text-decoration: none;
  cursor: pointer;
}
.icp_text:hover {
  color: #fff0ff;
}
.ig {
  margin: 0 10px;
  height: 10px;
  width: 2px;
  display: inline-block;
  background-color: #c6c6c6;
}
.tittle{
	font-size: 30px;
	margin: 30px;
	text-align: center;
}
.our_text{
	font-size: 16px;
	margin: 30px;
}
.in_box{
	
	display: inline-block;
	position: relative;
	
}
.in_imgbox{
	width: 90%;
	display: block;
	margin: 0 auto;
	//border-radius: 8px;
	overflow: hidden;
}
.in_img{
	width: 100%;
	display: block;
	margin: 0 auto;
	//border-radius: 8px;

}
.in_box:hover .in_img{
	transform: scale(1.2);
	transition: .5s;
}
.in_box:hover .in_text{
	opacity: 1;
	height: 80px;
	transition: .5s;
}
.in_text{
	
	height: 0px;
	color: #ffffff;
	background-color: rgba(0,  0, 0, .4);
	position: absolute;
	bottom: 0;
	left: 5%;
	z-index: 999;
	width: 90%;
	padding:0 5%;
	font-size: 18px;
	opacity: 0;
	line-height: 80px;

}
.tittle2{
	font-size: 30px;
	color: blue;
	margin-top: 50px;
	text-align: center;
}

</style>
