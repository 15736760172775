<template>
  <div class="navigation_bg">
    <div class="navigation_box col-11 col-sm-11 col-md-10 col-lg-8">
      <div class="left0">导航：</div>
      <router-link :to="item.link" v-for="(item, index) in arr" :key="index">
        <div class="left">
          {{ item.name }}<text v-if="index != arr.length - 1">&nbsp;></text>
        </div>
      </router-link>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["arr"],
  data() {
    return {
      /* 	navlist: [
				  { name: "首页", link:'/' },
		  { name: "公司简介", link:'' },
		 
		], */
    };
  },
};
</script>

<style scoped="scoped">
.navigation_bg {
  width: 100vw;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #768772;
  clear: both;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: relative;
  top: -30px;
}
.navigation_box {
  margin: 0 auto;
  height: 30px;
  padding-bottom: 50px;
  overflow-y: hidden;
  overflow: x-scroll;
  color: #fff0ff;
  line-height: 30px;
  clear: both;
}
.navigation_box .left {
  float: left;
  height: 100px;
  color: #d8ccd8;
  margin-right: 10px;
  font-size: 14px;
}
.navigation_box .left0 {
  float: left;
  height: 100px;
  color: #d8ccd8;
  margin-right: 10px;
  font-size: 14px;
}
.navigation_box .left:hover {
  color: #fff0ff;
}
</style>
