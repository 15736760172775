<template>
  <div style="now">
    {{ now }}
  </div>
</template>

<script>
	
import axios from 'axios'

export default {
	 
  data() {
    return {
      now: 0,
    };
  },
  created() {
	
	 
	  
	  
	
	
  },
  mounted() {
	  var that=this;
   axios({
        method: 'get',
        url: 'http://106.52.254.247:8080/cu/api/ContactMng_queryGroup? uid=18565755816&contactGroup=0',
        params: {
          id: 1
        }
      }).then((res) => {
        console.log('数据：', res)
      })
	   axios({
	        method: 'post',
	        url: 'http://106.52.254.247:8080/cu/ContactUserNew_queryGroup',
	       
	      }).then((res) => {
	        console.log('数据：', res)
			that.now=res.data;
	      })
	
 
  
  },
  
};
</script>

<style>
.now {
  width: 100vw;
}
</style>
