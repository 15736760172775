<template>
	<div class="box">
<video   style="width:100%;height:100%" controls autoplay>
	 <source :src="idd" type="video/mp4">
                  </video>

	
	</div>
</template>

<script >
	
	export default {
	  data() {
	    return {
	    idd:"",
		
	    };
	  },
	 created() {
	 	 console.log(this.$route.params.id);
		this.idd=require('@/assets/'+this.$route.params.id)
		 
	 },
	  methods: {
		  
	  },
	}

	
</script>

<style>
	.box{
		width: 100vw;
		height: 100vh;
		background-color: black;
	}
</style>